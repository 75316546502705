@tailwind base;
@tailwind components;
@tailwind utilities;


/* *, *::before, *::after {
    border: 1px solid red;
} */
body {
    overflow-x: hidden;
    /* overflow-y: hidden; */
}

/* .carousel-status{
    display: none;
} */

.modal {
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.5);
    /* Overlay effect: translucent background: black w/ partial opacity */
    z-index: 1;
    /* Overlay effect: positioned over other containers */
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    position: fixed;
    /* Fix position on the top-left corner */
    top: 0;
    left: 0;
    overflow: auto;
    /* Enable scroll if needed */
    padding-top: 100px;
    /* Location of the content container */
}

.ant-picker-ok .ant-btn {
    background: #2563Eb !important;
    color: #eee
}